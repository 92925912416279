export default {
  "home": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Браслет или #Бронирование:"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверять"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле обязательно к заполнению"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресторан: "])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор бронирования не найден"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У него нет минимального количества ночей для бронирования."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Феча:"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хора:"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лица:"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл. Адрес:"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии: (аллергия, праздники или особые пожелания)"])},
    "t12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["доступность"])},
    "t13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Бронирование создано"])},
    "t14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* У вас уже есть бронирование на эту дату"])},
    "t15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Достигнуто максимальное количество бронирований"])},
    "t16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Ресторан без мест"])},
    "t17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* В нерабочее время для бронирования"])},
    "t18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
    "t19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дресс-код:  формальный (Джентльмены: закрытая обувь, классические шорты или брюки, рубашка с рукавами)"])},
    "t20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помните, что допуск резервирования составляет 10 минут."])},
    "t21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чаевые не включены."])},
    "t22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно суток проживания или бронирования уже использовано"])},
    "t23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привет"])},
    "t24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", Спасибо за бронирование!"])},
    "t25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы забронировали номер с планом питания 'Только завтрак'. Для посещения ресторанов Umami и Chianti предварительный заказ не требуется. Бесплатное бронирование ужинов предусмотрено только для плана питания 'Все включено' с минимальным количеством ночей от 3 до 5."])},
    "t26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это бронирование уже проверено"])}
  }
}